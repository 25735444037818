import { Link } from 'gatsby';
import React from 'react';
import Col from 'react-bootstrap/Col';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import { FacebookIcon, InstagramIcon, YoutubeIcon } from '../icons/icons';
import Styles from './footer.module.scss';

const footerSections = [
    {
        name: 'Menü',
        links: [
            {
                name: 'Home',
                link: '/'
            },
            {
                name: 'About',
                link: '/about'
            },
            {
                name: 'Blog',
                link: '/blog'
            },
            {
                name: 'Kontakt',
                link: '/kontakt'
            }
        ]
    },
    {
        name: 'Neueste Beiträge',
        _id: 'POSTS'
    },
    {
        name: 'Rechtliches',
        links: [
            {
                name: 'Impressum',
                link: '/impressum'
            },
            {
                name: 'Datenschutz',
                link: '/datenschutz'
            }
        ]
    }
];

const FooterCol = ({section}) => (
    <React.Fragment>
        <h2 className={`text-white mb-2 ${Styles.heading}`}>{section.name}</h2>
        <div className={`${Styles.bar} mb-2`}></div>
        <ul className="list-unstyled mb-4 mb-md-5 mb-lg-0">
            {section.links.map((link, index) => (
                <li key={index} className="mb-1">
                    {link.date ? (<small className="text-white-50">{link.date}</small>): ''}
                    <Link className={`unstyled-link text-white ${Styles.link}`} to={link.link}>{link.name}</Link>
                </li>
            ))}
        </ul>
    </React.Fragment>
);

export default ({posts, maxPosts}) => {
    let footerPosts = [...posts.nodes];
    if (maxPosts) {
        footerPosts = footerPosts.splice(0, maxPosts)
    }
    footerSections.find(section => section._id === 'POSTS').links = footerPosts.map(node => {
        return {
            name: node.data.title.text,
            link: `/${node.uid}`,
            date: node.first_publication_date
        };
    });

    return (
        <footer className="bg-grey">
            <Container className="py-5">
                <Row>
                    <Col xs="12" md="6" lg="3">
                        <FooterCol section={footerSections[0]}/>
                    </Col>
                    <Col xs="12" md="6" lg="5">
                        <FooterCol section={footerSections[1]}/>
                    </Col>
                    <Col xs="12" md="6" lg="2">
                        <FooterCol section={footerSections[2]}/>
                    </Col>
                    <Col xs="12" md="6" lg="2" className="icon-container">
                        <h2 className={`text-white mb-2 ${Styles.heading}`}>Social</h2>
                        <div className={`${Styles.bar} mb-2`}></div>
                        <FacebookIcon pathClass="fill-white" linkClass="mr-2" size={36}/>
                        <InstagramIcon pathClass="fill-white" linkClass="mr-2" size={36}/>
                        <YoutubeIcon pathClass="fill-white" size={36}/>
                    </Col>
                </Row>
                <Row>
                    <Col>
                        <hr className="border-dark my-4"></hr>
                    </Col>
                </Row>
                <Row>
                    <Col xs="12" lg="4" xl="3" className="mb-4 mb-lg-0 text-md-center">
                        <Link to="/">
                            <img
                                src="/logo/mindfoe-brain-white-long.svg"
                                height="42"
                                className="d-inline-block align-top"
                                alt="Mindfoe Logo"
                            />
                        </Link>
                    </Col>
                    <Col xs="12" lg="6" className={`text-md-center ${Styles.footerNotes}`}>
                        <p>
                            Bei den mit Sternchen markierten Links handelt es sich um <Link to="/datenschutz#affiliate" className="text-white unstyled-link link-underline">Affiliate Links</Link>.
                            Wenn Du über diese Verlinkungen einen Artikel kaufst oder ein Angebot wahrnimmst,
                            erhalte ich eine kleine Provision. Dir entstehen keine weiteren Kosten.
                            Du supportest also indirekt meine Arbeit auf mindfoe!
                        </p>
                        <p>
                            &copy; {new Date().getFullYear()} Calvin Sievers
                        </p>
                    </Col>
                </Row>
            </Container>
        </footer>
    );
}
